<template>
  <v-container fluid>
    <v-data-table
      :headers="itemHeader"
      :items="items"
      :loading="loading"
      :items-per-page="-1"
      class="elevation-1 "
    >
      <template v-slot:top>
        <v-toolbar flat class="mb-7">
          <v-toolbar-title>الإعلانات</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <!-- add / edit item component -->
          <ItemForm
            @closeDialog="openDialog = false"
            @formSubmitted="formSubmitted"
            :formTitle="formTitle"
            :dialog="openDialog"
            :record="record"
            :collection="collection"
            v-if="openDialog"
            :items="items"
          />

          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="
              () => {
                record = null;
                formTitle = 'إضافة عنصر جديد';
                openDialog = true;
              }
            "
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>

          <!-- delete item -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template
        v-slot:item.actions="{ item }"
        style="textAlign:right !important"
      >
        <v-icon color="primary" small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>

        <v-icon color="error" small class="mr-2" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import ItemForm from "./comp/SessionForm";
import ApiService from "@/services/api.service";

export default {
  components: {
    ItemForm,
  },
  props: ["items", "itemHeader", "collection"],
  data() {
    return {
      loading: false,
      openDialog: false,
      dialogDelete: false,
      formTitle: "Add New Item",
      record: null,
    };
  },

  methods: {
    async loadData() {
      try {
        this.$emit("loadData");
      } catch (error) {
        console.log(error);
      }
    },

    editItem(item) {
      this.record = item;
      this.formTitle = "تعديل";
      this.openDialog = true;
    },

    deleteItem(record) {
      this.record = record;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        this.loading = true;
        const { data } = await ApiService.delete(
          `items/${this.collection}/${this.record.id}`
        );
        this.loading = false;
        this.closeDelete();
        let msg;
        msg = "deleted successfully";
        // }
        alert(msg);
        this.loadData();
      } catch (error) {
        console.log(error);
      }
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    formSubmitted(msg) {
      this.openDialog = false;
      alert(msg);
      this.loadData();
    },
  },
  mounted() {
    // this.loadData();
  },
};
</script>

<style></style>
